import type { MouseEvent } from 'react'
import type { ImageLoader } from 'next/image'
import Image from 'next/image'
import NextLink from 'next/link'
import { useRouter } from 'next/router'

import useDevicePixelRatio from '@cms/hooks/useDevicePixelRatio'
import { useGlobalContext } from '@cms/hooks/useGlobalContext'
import { FALLBACK_IMAGES, SMART_CROP_TOKENS } from '@cms/utils/constants'
import type { FormattedVideoProps } from '@cms/utils/types'
import {
  getDefaultImageLoader,
  getDevicePixelRatioPriority,
  getImageWithFallback,
  getRenditionImageLoader,
  getSmartCropImageLoaderByTokenName,
} from '@cms/utils/utils'
import { formattedVideo } from '@cms/utils/video'
import type { TeaserProps } from '@components/Teaser'
import { Teaser } from '@components/Teaser'
import type { ReferenceWeb } from '@knauf-group/ct-designs/utils/types'
import { pushRoute } from '@knauf-group/ct-designs/utils/utils'
import { useOneTrust } from '@knauf-group/ct-shared-nextjs/web/hooks'
import { linkToReferenceProps } from '@knauf-group/ct-shared-nextjs/web/utils/utils'

import { ChangeCookieSettingsCTA } from '../ChangeCookieSettingsCTA'
import type { TeaserWrapperProps } from './TeaserWrapper.types'

export const getImageLoader = (
  isProductImage: boolean,
  isSmartCropImage: boolean,
  devicePixelRatio: number,
): ImageLoader => {
  const devicePixelRatioPriority = getDevicePixelRatioPriority(devicePixelRatio)

  if (isProductImage) {
    return getRenditionImageLoader(devicePixelRatioPriority)
  }
  if (isSmartCropImage) {
    return getSmartCropImageLoaderByTokenName(
      SMART_CROP_TOKENS.CF_4X3_L,
      devicePixelRatioPriority,
    )
  }
  return getDefaultImageLoader()
}

const TeaserWrapper: React.FC<TeaserWrapperProps> = ({ fields, dataCy }) => {
  const router = useRouter()
  const { siteStructure } = useGlobalContext()
  const oneTrust = useOneTrust()

  const { image, primaryReference: primaryLink, videoReference } = fields || {}
  const primaryReference: ReferenceWeb = linkToReferenceProps(primaryLink, siteStructure)

  const { devicePixelRatio } = useDevicePixelRatio()

  const isProductImage = Boolean(fields?.productImage)
  const isSmartCropImage = !isProductImage && image?.[0]?.url
  // empty loader triggers the default one which applies the Optimization API which serves them directly from the app. See https://nextjs.org/docs/pages/building-your-application/optimizing/images#loaders
  const imageLoader = getImageLoader(isProductImage, isSmartCropImage, devicePixelRatio)
  const cookieBanner = <ChangeCookieSettingsCTA />
  const formatedVideoProps: FormattedVideoProps = {
    videoContent: videoReference,
    index: 1,
    isTargetingCookieSet: oneTrust.isTargetingCookieSet,
    cookieSettingsTile: cookieBanner,
  }
  const video = formattedVideo(formatedVideoProps)
  // since we are returning fallback images on getImageWithFallback, we need to ensure it does not overlap with video thumbnail
  const imageWithFallback =
    !video && getImageWithFallback(image?.[0], FALLBACK_IMAGES.DEFAULT_4_3_RATIO)

  const teaserProps: TeaserProps = {
    ...fields,
    image: imageWithFallback,
    video,
    primaryRef: primaryReference,
    isVideoCookieAccepted: video?.isVideoCookieAccepted,
    cookieSettingsTile: cookieBanner,
    handleClick: (event: MouseEvent<HTMLElement>) => {
      event.preventDefault()
      pushRoute(primaryReference, router)
    },
    nextLinkComponent: NextLink,
    nextImageComponent: Image,
    imageLoader,
    isProductImage,
    dataCy,
  }

  return <Teaser {...teaserProps} />
}

export default TeaserWrapper
